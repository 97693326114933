// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_sH d_bD";
export var storyRowWrapper = "w_hx d_hx d_bK";
export var storyLeftWrapper = "w_sJ d_bz d_bP";
export var storyWrapperFull = "w_sK d_cD";
export var storyWrapperFullLeft = "w_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "w_mv d_hy";
export var storyLeftWrapperCenter = "w_sL d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "w_sM d_hF";
export var storyHeader = "w_sN d_hD d_w d_cs";
export var storyHeaderCenter = "w_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "w_hB d_hB d_by d_dw";
export var storyBtnWrapper = "w_sP d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "w_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "w_rt d_fg d_Z";
export var imageWrapperFull = "w_rv d_w d_H d_bf d_Z";
export var SubtitleSmall = "w_qd z_qd z_sV z_s5";
export var SubtitleNormal = "w_qf z_qf z_sV z_s6";
export var SubtitleLarge = "w_qg z_qg z_sV z_s7";
export var textLeft = "w_dv";
export var textCenter = "w_dw";
export var textRight = "w_dx";